<div class="main-responsive">
    <div class="row show-grid chat-size">
        <div class="col-12 col-sm-12 col-md-5 col-lg-5 padding-0 text-left mb-15">
            <!-- <ngx-restaurant-selection></ngx-restaurant-selection> -->
            <nb-select class="store-selection" fullWidth [selected]="storeId" (selectedChange)="storeChange($event)" [(ngModel)]="storeId">
                <nb-select-label>
                    <div class="row">
                        <div class="col-sm-12 d-flex align-item-center">
                            <ng-container *ngIf="selectedStore?.logo?.url; else noImageTemplate">
                                <img [src]="selectedStore?.logo?.url" width="30" height="30" style="border-radius: 50px;">&nbsp;
                            </ng-container>
                            <ng-template #noImageTemplate>
                                <img [src]="constant.RESTAURANT_PLACEHOLDER_IMAGE" width="30" height="30" style="border-radius: 50px;">&nbsp;
                            </ng-template>
                            <p class="mt-1 store-name"><b>{{ selectedStore?.name?.length > 25 ? (selectedStore?.name | slice:0:25) + '...' : selectedStore?.name }}</b></p>
                        </div>
                        <div class="col-sm-12 extra_space" *ngIf="checkDuplicateStore(selectedStore?.name) == true">
                            <small>{{ selectedStore?.address?.street }}, 
                                   <span *ngIf="selectedStore?.address?.address_line_2">{{ selectedStore?.address?.address_line_2 }}, </span>
                                   {{ selectedStore?.address?.city }}, {{ selectedStore?.address?.state }} {{ selectedStore?.address?.zipcode }}
                            </small>
                        </div>
                    </div>
                </nb-select-label>
    
                <nb-option *ngFor="let store of stores" [value]="store._id">
                    <div class="row">
                        <div class="col-sm-12">
                            <ng-container *ngIf="store?.logo?.url; else noImageTemplate">
                                <img [src]="store?.logo?.url" width="30" height="30" style="border-radius: 50px;">&nbsp;
                            </ng-container>
                            <ng-template #noImageTemplate>
                                <img [src]="constant.RESTAURANT_PLACEHOLDER_IMAGE" width="30" height="30" style="border-radius: 50px;">&nbsp;
                            </ng-template>
                            {{store.name}}
                            <!-- <span class="chat-count">
                                {{ getChatCount(store?._id) }}
                            </span> -->
                            <span *ngIf="getChatCount(store?._id) > 0 && selectedStore._id != store?._id" class="count-online-status" nbTooltip="New messages" nbTooltipPlacement="top"></span>
                        </div>
                        <div class="col-sm-12" *ngIf="checkDuplicateStore(store.name) == true">
                            <small>{{ store?.address?.street }}, 
                                <span *ngIf="store?.address?.address_line_2">{{ store?.address?.address_line_2 }}, </span>
                                {{ store?.address?.city }}, {{ store?.address?.state }} {{ store?.address?.zipcode }}
                            </small>
                        </div>
                    </div>
                </nb-option>
            </nb-select>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 padding-0 text-left extra_remove"></div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 padding-0 text-left extra_remove"></div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 padding-0">
            <nb-card class="list-card vh_hight1 responsive-mobile" status="primary">
                <nb-card-header><h5 class="subheader-title">Users</h5></nb-card-header>
                <nb-list>
                    <!-- customerid -->
                    <nb-list-item class="user-list cursor" *ngFor="let user of users" [ngClass]="(user?.customerDetail?.customerid == activeUser?.customerDetail?.customerid) ? 'selected-list' : ''" (click)="selectChannel(user, chatTemplate)">
                        <nb-user [name]="getConvertedName(user?.customerDetail?.customer)" badgeStatus="primary" [badgeText]="user.badge"  badgePosition="bottom right"
                            [picture]="(user?.customerDetail?.customerpic && user?.customerDetail?.customerpic !== undefined && user?.customerDetail?.customerpic !== 'null') ?  user?.customerDetail?.customerpic : 'https://www.clipartmax.com/png/middle/424-4242023_fa-user-circle-icon.png'">
                        </nb-user>
                        <span *ngIf="user?.status == 'online'" class="online-status" nbTooltip="{{ getConvertedName(user?.customerDetail?.customer) }} is online" nbTooltipPlacement="top"></span>
                    </nb-list-item>
                </nb-list>
            </nb-card>
        </div>
        <div class="col-12 col-sm-12 col-md-8 col-lg-8 padding-0" *ngIf="!isChatResponsive">
            <ng-container *ngTemplateOutlet="chatTemplate"></ng-container>
            <!-- <nb-card status="primary">
                <nb-card-header>
                    <div class="row show-grid">
                        <div class="col-6 text-left">
                            <h5 class="subheader-title">{{getConvertedName(activeUser?.customerDetail?.customer) || 'Select Customer'}}
                                <span *ngIf="activeUser?.customerDetail?.customer">
                                    <nb-icon icon="info-outline" (click)="showUser(dialog, activeUser)"></nb-icon>
                                </span>
                            </h5>
                        </div>
                        <div class="col-6 text-right" *ngIf="activeChannel">
                            <h5 class="subheader-title">
                                <a style="cursor: pointer;" [nbTooltip]="constant.REFRESH" nbTooltipPlacement="top" (click)="fetchOldChatMessages(activeChannel)">
                                    <nb-icon icon="refresh-outline"></nb-icon>
                                </a>
                            </h5>
                        </div>
                    </div>
                </nb-card-header>
                <nb-chat class="chat" size="small">
                    <nb-chat-message *ngFor="let msg of messages" [type]="msg.type" [message]="msg.text"
                        [reply]="msg.reply" [sender]="getConvertedName(msg.user.name)" [date]="msg.date" [files]="msg.files"
                        [quote]="msg.quote" [latitude]="msg.latitude" [longitude]="msg.longitude" [ngClass]="{'read': msg.reply && msg.read}" (click)="getChatTimeStampDate(deleteMsgDialog, msg)">
                    </nb-chat-message>
                    <nb-chat-form *ngIf="activeChannel" (keyup)="onTypingMessage($event)" (send)="sendMessage($event)" [dropFiles]="true"></nb-chat-form>
                </nb-chat>
                <div *ngIf="displayTypingIndicator" class="typing-indicator">Typing...</div>
            </nb-card> -->
        </div>
    </div>
</div>

<ng-template #chatTemplate let-data let-ref="dialogRef">
    <nb-card [ngClass]="{'chat-header vh_hight2': !isChatResponsive}" status="primary">
        <nb-card-header>
            <div class="row show-grid">
                <div class="col-8 text-left">
                    <h5 class="subheader-title">
                        <a style="cursor: pointer;" [nbTooltip]="constant.BACK" nbTooltipPlacement="top" (click)="ref.close()" *ngIf="isChatResponsive">
                            <nb-icon icon="arrow-back-outline"></nb-icon>
                        </a>
                        {{getConvertedName(activeUser?.customerDetail?.customer) || 'Select Customer'}}
                        <span *ngIf="activeUser?.customerDetail?.customer">
                            <nb-icon icon="info-outline" (click)="showUser(dialog, activeUser)"></nb-icon>
                        </span>
                    </h5>
                </div>
                <div class="col-4 text-right" *ngIf="activeChannel">
                    <h5 class="subheader-title">
                        <a style="cursor: pointer;" [nbTooltip]="constant.REFRESH" nbTooltipPlacement="top" (click)="fetchOldChatMessages(activeChannel)">
                            <nb-icon icon="refresh-outline"></nb-icon>
                        </a>
                    </h5>
                </div>
                <!-- <div class="col-6 text-right" *ngIf="tempOrder && (activeUser?.customerDetail?.customerid == tempOrder?.customer)">
                    <button nbButton size="tiny" [nbTooltip]="constant.CLEAR" nbTooltipPlacement="top" outline class="top-button" (click)="clearAllTempOrder()">
                        {{constant.CLEAR}}
                    </button>&nbsp;&nbsp;
                    <button nbButton size="tiny" [nbTooltip]="constant.LOAD_ORDER_TO_POS" nbTooltipPlacement="top" outline class="top-button" (click)="loadOrderInPos()"> 
                        {{constant.LOAD_ORDER_TO_POS}}
                    </button>
                </div> -->
            </div>
        </nb-card-header>
        <nb-chat [ngClass]="{'chat': !isChatResponsive, 'chat-size-res': isChatResponsive}" [size]="isChatResponsive ? '' : 'small'">
            <nb-chat-message *ngFor="let msg of messages" [type]="msg.type" [message]="msg.text"
                [reply]="msg.reply" [sender]="getConvertedName(msg.user.name)" [date]="msg.date" [files]="msg.files"
                [quote]="msg.quote" [latitude]="msg.latitude" [longitude]="msg.longitude" [ngClass]="{'read': msg.reply && msg.read}" (contextmenu)="getChatTimeStampDate(deleteMsgDialog, msg); $event.preventDefault()">
            </nb-chat-message>
            <!-- <nb-chat-form *ngIf="activeChannel" (keyup)="onTypingMessage($event)" (send)="sendMessage($event)" [dropFiles]="true"></nb-chat-form> -->
        </nb-chat>

          <div class="form" *ngIf="activeChannel">
            <div class="dropped-files" *ngIf="droppedFiles?.length">
                <div class="preview-container" *ngFor="let preview of previewImages; let i=index">
                    <div class="one-image">
                        <img [src]="preview.files" class="preview">
                        <span class="remove" (click)="removeFile(i,preview.name)">&times;</span>
                    </div>
                </div>
              </div>
              <div class="message-row">
                <nb-form-field>
                    <input fullWidth autofocus (focus)="inputFocus = true" (blur)="inputFocus = false"
                    (mouseenter)="inputHover = true" (mouseleave)="inputHover = false" [(ngModel)]="newMsg" [class.with-button]="showButton" type="text" 
                    placeholder="{{ fileOver ? dropFilePlaceholder : messagePlaceholder }}" (keyup.enter)="sendMessage()" nbInput>
                    <nb-icon class="attachment-icon" ghost nbSuffix icon="attach-outline" (click)="selectFilePrompt.click()" pack="eva"></nb-icon>
                </nb-form-field>
                <input style="display: none;" type="file" multiple #selectFilePrompt (change)="imageAttach($event)" />
                <button
                  nbButton
                  [status]="getButtonStatus()"
                  *ngIf="showButton"
                  [class.with-icon]="!buttonTitle"
                  (click)="sendMessage()"
                  class="send-button"
                >
                  <nb-icon *ngIf="!buttonTitle; else title" [icon]="buttonIcon" pack="nebular-essentials"></nb-icon>
                  <ng-template #title>{{ buttonTitle }}</ng-template>
                </button>
              </div>
          </div>
        <div *ngIf="displayTypingIndicator" class="typing-indicator">Typing...</div>
    </nb-card>
</ng-template>

<!-- User Details Dialog -->
<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header><h5><b>User Details</b></h5></nb-card-header>

        <nb-card-body>
            <div *ngIf="showUserDetails != null; else elseUserBlock">
                <p><b>Name</b>: {{ getConvertedName(showUserDetails?.customerDetail?.customer) }}</p>
                <p><b>Phone</b>: 
                    <a href="tel:+1{{showUserDetails?.customerDetail?.customermobile}}" nbTooltip="{{constant.CALL_ON}} {{showUserDetails?.customerDetail?.customermobile | mask: '(000) 000-0000'}}" nbTooltipPlacement="top">
                        {{ showUserDetails?.customerDetail?.customermobile | mask:'(000) 000-0000' }}
                    </a>
                </p>
            </div>
            <ng-template #elseBlock>
                <p>No User Details Found</p>
            </ng-template>
        </nb-card-body>

        <nb-card-footer class="text-center">
            <button nbButton (click)="ref.close()" [nbTooltip]="constant.CLOSE" nbTooltipPlacement="bottom" status="danger">
                {{constant.CLOSE}}
            </button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<!-- Delete discount template -->
<ng-template #deleteMsgDialog let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header><h5><b>Delete Message</b></h5></nb-card-header>
        <nb-card-body>
            <label class="label" style="font-size: 14px;">{{constant.ARE_YOU_SURE}} delete message?</label><br>
        </nb-card-body>
        <nb-card-footer class="text-center">
            <button nbButton (click)="ref.close()" [nbTooltip]="constant.CANCEL" nbTooltipPlacement="bottom" status="info">{{constant.CANCEL}}</button>
            &nbsp;&nbsp;
            <button nbButton [nbTooltip]="constant.DISCOUNTS.YES_DELETE_IT" nbTooltipPlacement="bottom"  status="danger" type="button" (click)="ref.close('yes')">
                {{constant.DISCOUNTS.YES_DELETE_IT}}
            </button>
        </nb-card-footer>
    </nb-card>
</ng-template>