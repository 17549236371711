import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { environment } from '../../environments/environment';
import { NbAuthService } from '@nebular/auth';
import * as momentTz from 'moment-timezone';
import { ConstantProviderService } from '../providers/constant-provider.service';
import { NbToastrService } from '@nebular/theme';
import { fromEvent } from 'rxjs';
import { ChatService } from './chat.service';
import * as Pubnub from 'pubnub';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public EST_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';
  public USERTOKEN: any = '';
  public selectedStoreId = null;
  public selectedMenu = null;
  public selectedStoreName = null;
  public selectedStoreUid = null;
  public loggedInUserDetails = null;
  public loggedInUserRole = null;
  public WEB_SITE_URL = environment.WEBSITE_URL;
  public FIREBASE_TOKEN = '';
  public initTerminal: any;
  public terminalDevice: any;
  public alternativeData: any;

  public temporaryOrderList: any = [];
  public payoutSelectedDetailsOrderNoList: any = [];
  public allStoresList: any[] = [];
  public posItemList: any[] = [];

  public cmoContact: any;

  public LAZY_LOAD_DEFUALT_IMAGE = 'assets/images/trending1.png';

  public PER_PAGE_DATA = 10;
  public IMAGE_PER_PAGE_DATA = 100;

  constructor(
    private router: Router,
    private authService: NbAuthService,
    private constant: ConstantProviderService,
    private toasterService: NbToastrService
  ) {
    this.loggedInUserRole = this.getLoggedInUserRole();
    this.checkInternetConnectivity();
  }

  public checkInternetConnectivity() {
    fromEvent(window, 'online').subscribe(e => {
      window.location.reload();
    });

    fromEvent(window, 'offline').subscribe(e => {
      alert('Please check your internet connectivity.');
      window.location.reload();
    });
  }

  /**
  * Marks all controls in a form group as touched
  * @param formGroup - The form group to touch
  */
  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Returns String containing list of error messages to be displayed in a single string(toast)
   * @param errors Errors Object Received from API Response
   */
  getErrorMessageList(errors) {
    const errList = [];
    errors.forEach(error => {
      errList.push(error['message']);
    });

    return errList;
  }

  public getResponseErrorMessage(error: any, path?: any) {
    if (error.status == 401 || error.status == 403) {
      if (error.status == 403) {
        this.toasterService.danger(this.constant.ACCESS_DENIED, this.constant.ERROR);
      }
      setTimeout(() => {
        this.clearLocalStorage();
        this.authService.logout('email');
        this.router.navigate(['auth', 'login']);
      }, 1000)
    }
    else if (error.status == 410) {
      this.toasterService.danger(this.constant.NOT_AVAILABLE, this.constant.ERROR);
      setTimeout(() => {
        this.router.navigate(path);
      }, 1000)
    }
    else if (error?.error?.error?.details) return error.error.error.details[0].message;
    else if (!error?.error?.error?.details) return error.error.message;
  }

  public permissionChanged() {
    this.clearLocalStorage();
    this.authService.logout('email');
    this.router.navigate(['auth', 'login']);
  }

  /**
   * Set Selected Store
   */

  setStore(store: any) {
    if (store) {
      this.selectedStoreId = store._id;
      this.selectedStoreName = store.name;
      localStorage.setItem('selectedStore', this.selectedStoreId);
      localStorage.setItem('selectedStoreName', this.selectedStoreName);
      localStorage.setItem('selectedStoreDetails', JSON.stringify(store));
    }
  }
  setMenu(menu: any) {
    if (menu) {
      this.selectedMenu = menu;
      localStorage.setItem('selectedMenu', JSON.stringify(this.selectedMenu));
      console.log("selected menu id", this.selectedMenu);

    }
  }
  setStoreUid(storeUid) {
    this.selectedStoreUid = storeUid;
    localStorage.setItem('selectedStoreUid', this.selectedStoreUid);
  }

  setTerminal(data) {
    this.initTerminal = data;
  }

  getTerminal() {
    return this.initTerminal;
  }

  setConnectedDevice(device) {
    this.terminalDevice = device;
  }

  getConnectedDevice() {
    return this.terminalDevice;
  }

  deleteConnectedDevice() {
    this.terminalDevice = null;
  }

  getLocalStore() {
    return localStorage.getItem('selectedStore');
  }

  async getSelectedMenu() {
    const menuDetails = await localStorage.getItem('selectedMenu');
    console.log("menu", menuDetails);
    if (menuDetails) {
      return JSON.parse(menuDetails);
    } else {
      return null;
    }
  }

  getLocalStoreDetails() {
    return JSON.parse(localStorage.getItem('selectedStoreDetails'));
  }

  getLocalStoreName() {
    return localStorage.getItem('selectedStoreName');
  }

  getLocalStoreUid() {
    return localStorage.getItem('selectedStoreUid');
  }

  getLoggedInUserDetails() {
    const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    this.loggedInUserDetails = loggedInUserDetails;
    return loggedInUserDetails;
  }

  getLoggedInUserRole() {
    const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    let role = null;
    if (loggedInUserDetails) {
      role = loggedInUserDetails?.roles[0];
    }
    return role;
  }

  getLoggedInUserNgDelData() {
    const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    let ngDel = null;
    if (loggedInUserDetails.ngdel) {
      ngDel = loggedInUserDetails.ngdel;
    }
    return ngDel;
  }

  clearLocalStorage() {
    localStorage.clear();
    localStorage.removeItem('selectedUserRole');
    localStorage.removeItem('selectedStore');
    localStorage.removeItem('selectedStoreUid');
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('loggedInUserDetails');
    localStorage.removeItem('fbToken');
    localStorage.removeItem('user_all_stores');
    localStorage.removeItem('cmo:insights');
    localStorage.removeItem('cmo-chat-box');
    sessionStorage.clear();
    sessionStorage.removeItem('cmo:breadcrumb');
  }

  formatDate(date) {
    if (date) {
      if (moment(date).isValid) {
        return moment(date).toDate();
      } else {
        return date;
      }
    } else {
      return moment().toDate();
    }
  }

  getRestaurantPriceLevel(cost_for_two:number){
     switch(cost_for_two){
       case 1 : 
         return '$ - Entree items mostly under $10'
       case 2 :
         return '$$ - Entree items mostly between $10-$20'
       case 3 :
         return '$$$ - Entree items mostly between $20-$30'
       case 4 :
         return '$$$$ - Entree items mostly above $30'
       default:   
         return '-'
     }
  }

  setFirebaseToken() {
    this.FIREBASE_TOKEN = this.getFireBaseToken();
  }

  getFireBaseToken() {
    return localStorage.getItem('fbToken');
  }

  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, ['Restaurant', 'Order No', 'Date', 'Order Mode', 'Status', 'Type', 'Customer', 'Amount', 'Items']);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'No.,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  setTemporaryOrder(order: any) {
    this.temporaryOrderList = order;
  }

  getTemporaryOrder() {
    return this.temporaryOrderList;
  }

  public checkRouteForStoreDropdown(url: string) {
    let flag = false;
    const urlArray = [
      '/pages/customers', '/pages/customers/list', '/pages/customers/details/',
      '/pages/managers', '/pages/managers/list', '/pages/managers/add', '/pages/managers/edit/', '/pages/managers/details/',
      '/pages/orders/all', '/pages/orders/all/list', '/pages/orders/all/details/',
      '/pages/orders/refunds/details/',
      '/pages/stores/list', '/pages/stores/add', '/pages/stores/edit/', '/pages/stores/details/',
      '/pages/home', '/pages/initial-store-registration', '/pages/notification', '/pages/ng-delivery',
      '/pages/tickets', '/pages/tickets/list', '/pages/tickets/details/', '/pages/help-support', '/pages/profile',
      '/pages/delivery-setup/all-subscriptions', '/pages/insights/',
      '/pages/staffs', '/pages/staffs/list', '/pages/staffs/add/', '/pages/staffs/edit/', '/pages/staffs/details/',
      '/pages/payouts/payout-order', '/pages/payouts/payout-order/list', '/pages/payouts/payout-order/details',
      '/pages/discount/add', '/pages/discount/edit/', '/pages/discount/details/',
      '/pages/kiosk/list', 'pages/kiosk/edit', 'pages/kiosk/details',
      '/pages/general-config/razor-delivery',
      'pages/payouts/payout-history','pages/menus/add-item-v2','pages/menus/add-menu-v2' , 'pages/menus/add-category-v2', 'pages/general-config/delivery-setup/all-subscriptions/list'
    ];
    urlArray.forEach((list: any) => {
      if (url == '/pages/orders') flag = true;
      else if (url.includes(list)) flag = true;
    });
    return flag;
  }

  async setCMOContact(data: any) {
    this.cmoContact = await data;
  }

  public getCMOContact() {
    return this.cmoContact;
  }

  public decimalOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 46) return true;
    else if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    else return true;
  }

  public latterOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  public numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  public alphaNumericOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 32) return false;
    else if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 97 || charCode > 122)) return false;
    return true;
  }

  public refreshComponent(path: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([path]);
  }

  public convertToSlug(text: string): string {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');
  }

  public getDatesRanges(option: any) {
    let range: any = {};
    if (option == 'Daily') {
      range.start = moment().format('YYYY-MM-DD');
      range.end = moment().format('YYYY-MM-DD');

    } else if (option == 'Weekly') {
      range.end = moment().format('YYYY-MM-DD');
      range.start = moment().subtract(7, 'days').format('YYYY-MM-DD');

    } else if (option == 'Biweekly') {
      range.end = moment().format('YYYY-MM-DD');
      range.start = moment().subtract(15, 'days').format('YYYY-MM-DD');

    } else if (option == 'Monthly') {
      range.end = moment().format('YYYY-MM-DD');
      range.start = moment().subtract(30, 'days').format('YYYY-MM-DD');

    } else if (option == 'Yearly') {
      range.end = moment().format('YYYY-MM-DD');
      range.start = moment().subtract(12, 'months').format('YYYY-MM-DD');
    }

    return range;
  }

  public getDateInFormat(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }

  public getPreparedChartsWithSales(report: any, filterOption: string) {
    let options: any = {};
    const days = filterOption != 'Yearly' ? report.days : report.months;
    const orders = filterOption != 'Yearly' ? report.day_values : report.month_values;
    const sales = filterOption != 'Yearly' ? report.day_sales : report.month_sales;
    const maxSales = Math.max(...sales);
    const interval = Math.round(maxSales / 15);

    options = {
      legend: {
        data: ['Orders', 'Sales'],
        align: 'left',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: "#999"
          }
        }
      },
      xAxis: {
        type: 'category',
        data: days,
        axisPointer: {
          type: 'shadow'
        }
      },
      yAxis: [
        {
          type: 'value',
          name: 'Orders',
          interval: 5
        },
        {
          type: 'value',
          name: 'Sales',
          interval: interval,
          axisLabel: {
            formatter: '${value}'
          }
        }
      ],
      series: [
        {
          name: 'Orders',
          type: 'bar',
          data: orders,
          animationDelay: (idx) => idx * 10,
        },
        {
          name: 'Sales',
          type: 'line',
          data: sales,
          yAxisIndex: 1,
          smooth: true,
          animationDelay: (idx) => idx * 10,
          label: {
            formatter: '${value}'
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: (idx) => idx * 5,
    }

    return options;
  }

  public getPreparedChartsWithoutSales(report: any, filterOption: string, valueName: any) {
    let options: any = {};
    const days = filterOption != 'Yearly' ? report.days : report.months;
    const orders = filterOption != 'Yearly' ? report.day_values : report.month_values;

    options = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: days,
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        type: "value"
      },
      series: [
        {
          name: valueName,
          type: 'bar',
          barWidth: '60%',
          data: orders
        },
      ],
    };

    return options;
  }

  public getPreparedChartsForReviws(report: any, filterOption: string) {
    let options: any = {};
    const days = filterOption != 'Yearly' ? report.positive.data.days : report.positive.data.months;
    const negative = filterOption != 'Yearly' ? report.negative.data.day_values : report.negative.data.month_values;
    const positive = filterOption != 'Yearly' ? report.positive.data.day_values : report.positive.data.month_values;

    options = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: days,
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        type: "value"
      },
      series: [
        {
          name: 'Positive',
          type: 'bar',
          barWidth: '40%',
          data: positive
        },
        {
          name: 'Negative',
          type: 'bar',
          barWidth: '40%',
          data: negative
        },
      ],
    };

    return options;
  }

  public getPreparedPieCharts(report: any, filterOption: string) {
    let options: any = {};
    let dataArray: any[] = [];

    if (filterOption != 'Yearly') {
      for (let i = 0; i < report.days.length; i++) {
        dataArray.push({ name: report.days[i], value: report.day_values[i] });
      }
    } else {
      for (let i = 0; i < 12; i++) {
        dataArray.push({ name: report.months[i], value: report.month_values[i] });
      }
    }

    options = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: 'left',
      },
      series: [
        {
          name: 'Avg. Prepare Time',
          type: 'pie',
          radius: '80%',
          data: dataArray,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    return options;
  }

  public formattingStoreKeyName(key: any) {
    let ind = this.constant.STORE_KEY_NAME.findIndex((list: any) => { return list.value == key });
    if (ind > -1) return this.constant.STORE_KEY_NAME[ind].label;
  }

  public formattingDiscountKeyName(key: any) {
    let ind = this.constant.DISCOUNT_KEY_NAME.findIndex((list: any) => { return list.value == key });
    if (ind > -1) return this.constant.DISCOUNT_KEY_NAME[ind].label;
  }

  public convertToFirstLetterCapital(text: string) {
    let splitStr = text.split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  public setAlternativeData(data: any) {
    this.alternativeData = data;
  }

  public getAlternativeData() {
    return this.alternativeData;
  }

  public getRestaurantCurrentSlotTime(restaurant: any, slotTime?: string) {
    if (restaurant?.apt_hours?.length) {
      const storeDay = momentTz.tz(restaurant?.settings?.timezone_id).format('dddd');
      const dayData = restaurant?.apt_hours.find((list: any) => { return list.day == storeDay });
      if (dayData) {
        const time = momentTz.tz(restaurant?.settings?.timezone_id).format('hh:00 A');
        const findSlot = dayData.options.find((list: any) => { return list.slot == (slotTime ? slotTime : time) });
        return findSlot?.min;
      }
    }
  }

  public checkDayFormat(day: string) {
    for (let wDay of this.constant.WEEK_DAYS) {
      if (day.includes(wDay)) {
        return wDay;
      }
    }
  }

  public findDecimalPointIndex(value: any) {
    if (value) {
      const split = value.toString().split('.');
      if (split?.length && (split[0]?.length <= 2 || split[1]?.length <= 2)) return 5;
      else return 2;
      // const ind = value.toString().indexOf('.');
      // if (ind == 1) return 4;
      // else if (ind == 2) return 5;
      // else if (ind == 0) return 6;
      // else if (ind != 0 && ind != 1 && ind != 2) return 3;
    }
  }

  public checkRouteForBreadCrumb(url: string) {
    let flag = true;
    const urlArray = [
      '/pages/home', '/pages/store-pos', '/pages/stores/list', 'pages/orders/all/list',
      '/pages/menus/menu-editor', '/pages/reviews-ratings', '/pages/discount/list',
      '/pages/staffs/list', '/pages/media-gallery', '/pages/under-review',
      '/pages/notification', '/pages/tickets/list', '/pages/profile',
      '/pages/initial-store-registration'
      // '/pages/customers/list', '/pages/managers/list', '/pages/orders/list',
      // '/pages/kitchen-staff/list',
    ];
    urlArray.forEach((list: any) => {
      if (url.includes(list)) flag = false;
    });
    return flag;
  }

  public changeCardBrandName(brand: string): string {
    if (brand.toLocaleLowerCase() == 'visa') return 'VISA';
    else if (brand.toLocaleLowerCase() == 'mastercard') return 'MasterCard';
    else if (brand.toLocaleLowerCase() == 'discover') return 'Discover';
    else if (brand.toLocaleLowerCase() == 'amex') return 'American Express';
    else if (brand.toLocaleLowerCase() == 'unionpay') return 'UnionPay';
    else if (brand.toLocaleLowerCase() == 'jcb') return 'JCB';
  }

  public getOrderVariantToppingDetail(orderData: any, name: any) {
    const top: any = [];
    if (name == 'topping') {
      orderData?.toppings?.forEach((data: any) => {
        top.push(data.name);
      });

    } else {
      orderData?.variants?.forEach((data: any) => {
        const temp = `${data.name}: ${data.option.name}`;
        top.push(temp);
      });
    }
    return top.join('; ');
  }

  public convertStringToTimepickerFormat(time: string) {
    const convert: any = moment(time, 'hh:mm A');
    return new Date(convert);
  }

  public getFormattedProxyNumber(num: any) {
    return num?.slice(2, 12);
  }

  public convertToTimestamp(time: any) {
    let date = moment(time).format('MM/DD/YYYY hh:mm A');
    let diff = moment().diff(moment(date, 'MM/DD/YYYY hh:mm A'));
    let durationMin: any = moment.duration(diff).asMinutes();
    let durationHour: any = moment.duration(diff).asHours();
    let durationDay: any = moment.duration(diff).asDays();
    let durationYear: any = moment.duration(diff).asYears();

    if (durationMin < 1) return `Just now`;
    else if (parseInt(durationMin) == 1) return `${parseInt(durationMin)} min ago`;
    else if (parseInt(durationMin) > 1 && parseInt(durationMin) < 60) return `${parseInt(durationMin)} mins ago`;
    else if (parseInt(durationHour) == 1) return `${parseInt(durationHour)} hour ago`;
    else if (parseInt(durationHour) > 1 && parseInt(durationHour) < 24) return `${parseInt(durationHour)} hours ago`;
    else if (parseInt(durationDay) == 1) return `Yesterday ${moment(date, 'MM/DD/YYYY hh:mm A').format('@ h:mm A')}`;
    else if (parseInt(durationDay) > 1) return `${moment(date, 'MM/DD/YYYY hh:mm A').format('MMM DD @ h:mm A')}`;
    else if (parseInt(durationYear) >= 1) return `${moment(date, 'MM/DD/YYYY hh:mm A').format('MMM DD, YYYY @ h:MM A')}`;
  }


  //convert time to 24 hr format 
  public convertTimeFormat(timeArrayOfObjects:any,timeSlot:any     
    ){
    
      timeArrayOfObjects.forEach((timeObj:any) => {
       if(!(/\d{1,2}:\d{2} [AP]M/.test(timeObj.start_time)) && !(/\d{1,2}:\d{2} [AP]M/.test(timeObj.end_time))){
         const timeParts = timeObj.start_time.split(':');
         const timeParts2 = timeObj.end_time.split(':');
  
         let hours = parseInt(timeParts[0], 10);
         let hours2 = parseInt(timeParts2[0], 10);
  
         const minutes = timeParts[1];
         const minutes2 = timeParts2[1];
  
     
         const meridian = hours >= 12 ? 'PM' : 'AM';
         const meridian2 = hours2 >= 12 ? 'PM' : 'AM';
     
         hours = hours % 12;
         hours = hours || 12; // Convert 0 to 12
     
         hours2 = hours2 % 12;
         hours2 = hours2 || 12; // Convert 0 to 12

          // Add leading zero if hours is a single digit
          const formattedHours = hours.toString().padStart(2, '0');
          const formattedHours2 = hours2.toString().padStart(2, '0');
  
         timeObj.start_time = `${formattedHours}:${minutes} ${meridian}`
         timeObj.end_time = `${formattedHours2}:${minutes2} ${meridian2}`
       }
       })

       //convert open late time to 12 hr format before displaying
       if(timeSlot!=null&&timeSlot!=''&&timeSlot!=undefined&&timeSlot.late!=''&&timeSlot.late!=null&&timeSlot.late!=undefined){
        if (!(/\d{1,2}:\d{2} [AP]M/.test(timeSlot.late))) {
         const lateParts = timeSlot.late.split(':');
         let lateHours = parseInt(lateParts[0], 10);
         const lateMinutes = lateParts[1];
         const lateMeridian = lateHours >= 12 ? 'PM' : 'AM';
   
         lateHours = lateHours % 12;
         lateHours = lateHours || 12; // Convert 0 to 12
   
         const formattedLateHours = lateHours.toString().padStart(2, '0');
   
         timeSlot.late = `${formattedLateHours}:${lateMinutes} ${lateMeridian}`;
       }
     }
 


     
      
   }
  public setAllStoresList(stores: any) {
    localStorage.setItem('user_all_stores', JSON.stringify(stores));
  }

  public getAllStoresList() {
    return JSON.parse(localStorage.getItem('user_all_stores'));
  }

  public removeAllStoresList() {
    localStorage.removeItem('user_all_stores');
  }

  public checkSubscriptionDetailsShowOrHide(status: string) {
    if (status == 'active' || status == 'canceled' || status == 'trialing') return true;
    else return false;
  }

  public setPosItemList(list: any) {
    this.posItemList = list;
  }

  public getPosItemList() {
    return this.posItemList;
  }

  public windowScrollUp() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public afterAuthRedirection() {
    const role = localStorage.getItem('selectedUserRole');
    const stores = localStorage.getItem('cmo-stores-length');

    if (role == 'kitchenstaff' || role == 'storestaff') {
      this.router.navigate(['pages', 'orders', 'all', 'list'], { replaceUrl: true });

    } else if (role == 'storeowner' || role == 'storemanager') {
      if (stores == '0' && role == 'storeowner') {
        this.router.navigate(['pages', 'initial-store-registration'], { replaceUrl: true });

      } else {
        this.router.navigate(['pages', 'home'], { replaceUrl: true });
      }
    }
  }

  public convertTimestampToDate(timestamp, timezone) {
    return moment(timestamp).tz(timezone).format('MM-DD-YYYY hh:mm A');
  }

  public getTimezoneList() {
    return moment.tz.zonesForCountry('US');
  }
}
